import React from 'react';
import {
    FooterContainer,
    ErrorContainer,
    ErrorText,
    StyledContainer,
    AnnualPremiumText,
    PremiumBreakdownContainer,
    StyledAnnualAmountText,
    StyledAnnualPremiumContainer,
    StyledBtnWrapper
} from './styles';
import { ISummaryFooter } from './types';
import { isCheckoutDisabled } from '../../../utils/featureFlags';
import { LoopButton } from '@loophealth/loop-ui-web-library';

const SummaryFooter: React.FunctionComponent<ISummaryFooter> = ({
    memberTopUpPremium,
    onSaveDetailsClick,
    loading,
    policyId,
    error
}) => {
    return (
        <FooterContainer>
            <StyledContainer $alignRight={!memberTopUpPremium}>
                {memberTopUpPremium ? (
                    <PremiumBreakdownContainer>
                        <StyledAnnualPremiumContainer>
                            <AnnualPremiumText>Annual Premium</AnnualPremiumText>
                            <StyledAnnualAmountText>
                                &#8377; {memberTopUpPremium?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </StyledAnnualAmountText>
                        </StyledAnnualPremiumContainer>
                    </PremiumBreakdownContainer>
                ) : null}
                {(isCheckoutDisabled(policyId) && <></>) || (
                    <StyledBtnWrapper>
                        <LoopButton variant="filled" onClick={onSaveDetailsClick} isLoading={loading}>
                            Complete Registration
                        </LoopButton>
                    </StyledBtnWrapper>
                )}
            </StyledContainer>
            {error?.message ? (
                <ErrorContainer>
                    <ErrorText>{error?.message}</ErrorText>
                </ErrorContainer>
            ) : null}
        </FooterContainer>
    );
};

export default SummaryFooter;

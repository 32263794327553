import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';

const applicationState = createSlice({
    name: 'applicationState',
    initialState,
    reducers: {
        resetState(state) {
            state.showWelcomeScreen = initialState.showWelcomeScreen;
        },
        setShowWelcomeScreen(state, { payload }) {
            state.showWelcomeScreen = payload.showWelcomeScreen;
        },
        setShowCallbackToolTip(state, action) {
            state.showCallbackToolTip = action.payload.showCallbackToolTip;
        },
        setTopupPolicyId(state, action) {
            state.topupPolicyId = action.payload.topupPolicyId;
        }
    },
    extraReducers: {
        'common/cleanup': (state) => {
            state.showWelcomeScreen = initialState.showWelcomeScreen;
            state.showCallbackToolTip = initialState.showCallbackToolTip;
            state = initialState;
        }
    }
});
export default applicationState.reducer;
export const { setShowWelcomeScreen, setShowCallbackToolTip, setTopupPolicyId, resetState } = applicationState.actions;

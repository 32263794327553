import React from 'react';
import { StyledContainer, IdentityWrapper, StyledRowContainer } from './styles';
import { InsuranceIdentity, SummaryCardDetail } from '../../atoms';
import { ISummaryCardHeader } from './types';

const InsuranceCardHeader: React.FunctionComponent<ISummaryCardHeader> = ({
    policyImage = null,
    insuranceName,
    insuranceCategory,
    familyStructure,
    ...rest
}) => {
    return (
        <StyledContainer>
            <StyledRowContainer>
                <IdentityWrapper>
                    <InsuranceIdentity
                        policyImage={policyImage}
                        policyName={insuranceName}
                        policyCategory={insuranceCategory}
                        familyStructure={familyStructure}
                    />
                </IdentityWrapper>
                <SummaryCardDetail {...rest} />
            </StyledRowContainer>
        </StyledContainer>
    );
};

export default InsuranceCardHeader;

/* eslint-disable */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/functions';
import useToken from '../utils/hooks/useToken';
import { topUpDefaultConfig } from '../utils/data';
import * as Sentry from '@sentry/react';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const tokens = useToken();
let FirebaseProvider: firebase.app.App;
if (!firebase.apps.length) {
    FirebaseProvider = firebase.initializeApp(firebaseConfig, process.env.REACT_APP_FIREBASE_APP_NAME);
} else {
    FirebaseProvider = firebase.app(); // if already initialized, use that one
}
export const Firestore = FirebaseProvider.firestore();
export const FirebaseStorage = FirebaseProvider.storage();
export const FirebaseFunctions = FirebaseProvider.functions('asia-south1');
export const FirebaseCaptcha = firebase.auth;
export const FirebaseAuth = firebase.auth();
export const FirebaseTimestamp = firebase.firestore.Timestamp;
export const FirebaseFieldValue = firebase.firestore.FieldValue;
export const FirebaseRemoteConfig = FirebaseProvider.remoteConfig();
export default FirebaseProvider;

const remoteConfig = firebase.remoteConfig();
remoteConfig.settings = {
    fetchTimeoutMillis: 20000,
    minimumFetchIntervalMillis: 1
};
remoteConfig.defaultConfig = {
    enrolmentConfig: JSON.stringify(topUpDefaultConfig)
};

export const getContentType = (res: any) => {
    const isJSON = res.headers.get('Content-Type')?.startsWith('application/json') || false;

    if (isJSON) {
        return 'JSON';
    }

    const isText = res.headers.get('Content-Type')?.startsWith('text') || false;
    if (isText) {
        return 'Text';
    }

    return 'Unsupported';
};

export const processResponse = async (res: any) => {
    const contentType = getContentType(res);
    if (res.ok) {
        if (contentType === 'JSON') {
            return await res.json();
        } else {
            return res;
        }
    }
};

type IMethod = 'DELETE' | 'PUT' | 'POST' | 'GET';

export const accessAPI = async (
    method: IMethod,
    url: string,
    body?: Record<string, unknown>
): Promise<Response | Error> => {
    try {
        const baseUrl = process.env.REACT_APP_FIREBASE_FUNCTION_BASE_URL;
        const token = await tokens.checkToken();
        const result = await fetch(baseUrl + url, {
            method: method,
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                'loop-source': process.env.REACT_APP_NAME ?? ''
            }
        });

        if (result.ok) {
            return await processResponse(result);
        } else {
            const errorMessage = (await result.json()).message;
            // if result is such tht token is expired then process new token
            if (tokens.tokenIsExpired(errorMessage)) {
                await tokens.setToken();
                return await accessAPI(method, url, body);
            }
            if (Array.isArray(errorMessage)) {
                throw new Error(JSON.stringify(errorMessage));
            }
            throw new Error(errorMessage);
        }
    } catch (e) {
        throw e;
    }
};

export const get = async (url: string): Promise<Response | Error> => {
    try {
        return await accessAPI('GET', url);
    } catch (e) {
        Sentry.captureException(new Error(`GET Topup: ${(e as Error).message}`, {
          cause: e
        }));
        throw e;
    }
};

export const post = async (url: string, body: Record<string, unknown>): Promise<Response | Error> => {
    try {
        return await accessAPI('POST', url, body);
    } catch (e) {
        Sentry.captureException(new Error(`POST Topup: ${(e as Error).message}`, {
          cause: e
        }));
        throw e;
    }
};

export const del = async (url: string, body: Record<string, unknown>): Promise<Response | Error> => {
    try {
        return await accessAPI('DELETE', url, body);
    } catch (e) {
        Sentry.captureException(new Error(`DELETE Topup: ${(e as Error).message}`, {
          cause: e
        }));
        throw e;
    }
};

export const put = async (url: string, body: Record<string, unknown>): Promise<Response | Error> => {
    try {
        return await accessAPI('PUT', url, body);
    } catch (e) {
        Sentry.captureException(new Error(`PUT Topup: ${(e as Error).message}`, {
          cause: e
        }));
        throw e;
    }
};

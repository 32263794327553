import React, { useState, useEffect, FC } from 'react';
import AddMemberCard from '../AddMemberCard';
import {
    StyledContainer,
    StyledMutedWarningText,
    StyledNomineesContainer,
    StyledTitle,
    StyledTitleWarningText
} from './styles';
import { DeleteMemberModal } from '../index';

import { colorLibrary } from '../../../theme';
import AddNomineeModal from './AddNomineeModal';
import NomineeMemberCard from './NomineeMemberCard';
import { defaultDateFormat } from '../../../utils/common';
import { IManageNomineesComponent, NomineeData } from './types';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import { Loader } from '../../atoms';
import { MemberClickAction, getPercentageConsumed, onMemberAddUtil, onMemberDeleteUtil } from './utils';
import { useNomineesData } from '../../hooks/useNomineesData';
import { Toast } from '@loophealth/loop-ui-web-library';

const ManageNomineesComponent: FC<IManageNomineesComponent> = ({
    userId,
    policyId,
    setConsumedPercentage,
    consumedPercentage
}) => {
    const toast = Toast.useToast();
    const [isNomineeLoading, setIsNomineeLoading] = useState(true);
    const maxNumberOfNominees = useSelector(
        (state: ReduxState) => state.stepperData.nomineeConfig.data.maximumNomineeSelection
    );
    const isNomineeConfigLoading = useSelector((state: ReduxState) => state.stepperData.nomineeConfig.loading);
    const { nomineesData, refreshNominees } = useNomineesData(userId, policyId, setIsNomineeLoading);
    const [isDeleteMemberModalVisible, toggleDeleteMemberModalVisible] = useState(false);
    const [isManageNomineeModalVisible, toggleManageNomineeModalVisible] = useState(false);
    const [selectedNominee, setSelectedNominee] = useState<NomineeData | undefined>();

    useEffect(() => {
        setConsumedPercentage(getPercentageConsumed(nomineesData));
    }, [nomineesData]);

    // Edit And Delete Flow-
    const onMemberActionClicked = (nominee: NomineeData, actionType: MemberClickAction) => {
        setSelectedNominee(nominee);
        actionType === MemberClickAction.EDIT
            ? toggleManageNomineeModalVisible(true)
            : toggleDeleteMemberModalVisible(true);
    };

    const onCloseManageNomineeModal = (isVisible: boolean) => {
        toggleManageNomineeModalVisible(isVisible);
        setSelectedNominee(undefined);
    };

    if (isNomineeLoading || isNomineeConfigLoading) {
        return (
            <StyledContainer>
                <Loader />
            </StyledContainer>
        );
    }

    return (
        <StyledContainer>
            <StyledTitle>
                Nominees&nbsp;
                {consumedPercentage !== 100 && nomineesData.length ? (
                    <StyledTitleWarningText>
                        (Please add another nominee to assign remaining {100 - consumedPercentage}% of Sum Insured)
                    </StyledTitleWarningText>
                ) : !nomineesData.length ? (
                    <StyledMutedWarningText>(Please add atleast 1 nominee for this policy)</StyledMutedWarningText>
                ) : (
                    ''
                )}
            </StyledTitle>
            <StyledNomineesContainer>
                {nomineesData.map((nominee: NomineeData, index) => (
                    <NomineeMemberCard
                        onMemberEdit={() => onMemberActionClicked(nominee, MemberClickAction.EDIT)}
                        onMemberDelete={() => nominee.id && onMemberActionClicked(nominee, MemberClickAction.DELETE)}
                        firstName={nominee.firstName}
                        lastName={nominee.lastName}
                        relationship={nominee.relationship}
                        gender={nominee.gender}
                        doB={defaultDateFormat(nominee.dob.getTime() / 1000)}
                        percentage={nominee.percentage}
                        color={colorLibrary[index % colorLibrary.length]}
                        key={index}
                    />
                ))}
                {(nomineesData.length < maxNumberOfNominees && consumedPercentage !== 100 && (
                    <>
                        {Array.from(Array(Math.max(maxNumberOfNominees - nomineesData.length, 0)), () => {
                            return (
                                <AddMemberCard
                                    onClick={() => toggleManageNomineeModalVisible(true)}
                                    relationship={`Nominee`}
                                    key={'nominee-card'}
                                />
                            );
                        })}
                    </>
                )) || <></>}
            </StyledNomineesContainer>
            <DeleteMemberModal
                setIsVisible={toggleDeleteMemberModalVisible}
                isVisible={isDeleteMemberModalVisible}
                confirmDelete={() =>
                    onMemberDeleteUtil(
                        selectedNominee,
                        toggleDeleteMemberModalVisible,
                        setSelectedNominee,
                        refreshNominees,
                        toast
                    )
                }
                dependentId={'dependentIdToDelete'}
                selectedRelationship={'Nominee'}
            />
            <AddNomineeModal
                selectedNominee={selectedNominee}
                consumedPercentage={consumedPercentage}
                isVisible={isManageNomineeModalVisible}
                setIsVisible={onCloseManageNomineeModal}
                onSubmit={(nominee: NomineeData) =>
                    onMemberAddUtil(
                        selectedNominee,
                        nominee,
                        userId,
                        policyId,
                        toggleManageNomineeModalVisible,
                        setSelectedNominee,
                        refreshNominees,
                        consumedPercentage,
                        toast
                    )
                }
            />
        </StyledContainer>
    );
};

export default ManageNomineesComponent;

import React from 'react';
import { replaceFamilyStructureText } from '../../../utils/common';
import {
    StyledContainer,
    StyledInsuranceDetailContainer,
    StyledInsuranceDetailContent,
    StyledInsuranceDetailHeading
} from './styles';
import { ISummaryCardDetail } from './types';


const InsuranceCardDetail: React.FunctionComponent<ISummaryCardDetail> = ({
    sumInsured,
    coveredMembers
}) => {
    return (
        <StyledContainer>
            <StyledInsuranceDetailContainer>
                <StyledInsuranceDetailHeading>SUM INSURED</StyledInsuranceDetailHeading>
                <StyledInsuranceDetailContent>{sumInsured.trim() ? sumInsured : 'N/A'}</StyledInsuranceDetailContent>
            </StyledInsuranceDetailContainer>
            <StyledInsuranceDetailContainer>
                <StyledInsuranceDetailHeading>COVERED MEMBERS</StyledInsuranceDetailHeading>
                <StyledInsuranceDetailContent>
                    {replaceFamilyStructureText(coveredMembers)}
                </StyledInsuranceDetailContent>
            </StyledInsuranceDetailContainer>
        </StyledContainer>
    );
};

export default InsuranceCardDetail;
